import { useState } from "react";
import { AdminMfaManager } from "./AdminMfaManager";
import { getFromSS, setInSS } from "../localStorage";
import { getAccessTokenPayload } from "../api";
import { useSelectedCompany } from "../custom-hooks";

const ADMIN_MFA_SENT_DATE_LS_KEY = "ADMIN_MFA_SENT_DATE_LS_KEY";

export const useAdminMfaManager = () => {
  const { selectedCompany } = useSelectedCompany();
  const TIMER_DURATION = 60;
  const [loadingSendCode, setSendCodeLoading] = useState(false);
  const [loadingVerifyCode, setVerifyCodeLoading] = useState(false);

  const sendCode = async () => {
    setSendCodeLoading(true);
    if (timeRemaining() === 0) {
      setInSS({
        key: ADMIN_MFA_SENT_DATE_LS_KEY,
        value: new Date().toISOString(),
      });
      try {
        await AdminMfaManager.sendVerificationCode(selectedCompany.id);
      } catch {}
    }
    setSendCodeLoading(false);
  };

  const verifyCode = async (code: string) => {
    setVerifyCodeLoading(true);
    const success = await AdminMfaManager.verifyCode(code);
    setVerifyCodeLoading(false);
    return success;
  };

  const timeRemaining = () => {
    const startedData = getFromSS(ADMIN_MFA_SENT_DATE_LS_KEY);
    if (!startedData) {
      return 0;
    }
    const startedDate = new Date(startedData);
    const diffSeconds = Math.floor(
      (new Date().getTime() - startedDate.getTime()) / 1000
    );
    if (diffSeconds > TIMER_DURATION) {
      return 0;
    }
    return TIMER_DURATION - diffSeconds;
  };

  const getAuthenticatedCode = async (): Promise<string | undefined> => {
    const accessToken = await getAccessTokenPayload();
    return AdminMfaManager.getAuthenticatedCode(accessToken.employeeId);
  };

  return {
    sendCode,
    verifyCode,
    timeRemaining,
    getAuthenticatedCode,
    loadingSendCode,
    loadingVerifyCode,
  };
};
