/**
 * Decode and parse a jwt token
 * @param token jwt token
 * @returns jwt object
 */
export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 * Compare timestamp with now() and return if is expired
 * @param exp expiration timestamp
 * @returns true when exp is expired
 */
export const epochDateCompare = (exp: number): boolean => {
  return exp <= Date.now();
};

export function appendRedirectUrlToPath(path: string): string {
  const redirectUrl = new URL(location.href).searchParams.get("redirectTo");
  if (redirectUrl) {
    return `${path}?redirectTo=${encodeURIComponent(redirectUrl)}`;
  }

  if (
    location.pathname !== "/authentication/login" &&
    location.pathname !== "/authentication/access-selection" &&
    location.pathname !== "/home"
  ) {
    return `${path}?redirectTo=${encodeURIComponent(location.pathname)}`;
  }

  return path;
}
