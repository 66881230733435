import { Buffer } from "buffer";
import axios from "axios";

import { services } from "./services";
import { Axios as ServiceAxios } from "./api";

interface IUploadFileToS3 {
  file: FileReader | undefined;
  filepath: string;
  contentType: string;
  externalToken?: string;
}
interface getS3PresignedProps {
  filename: string;
  module?: string;
  externalToken?: string;
}
interface UploadProps {
  key: string;
  file: File;
  fileName?: string;
  module?: string;
  externalToken?: string;
  onProgress?: (percentage: number) => any;
}

const buildEnv = process.env.BUILD_ENV;
const axiosInstance = axios.create();

const Axios = async ({ method, url, data, configs = {} }) => {
  const options = {
    ...{ baseURL: services["aws"][buildEnv] },
    ...{ method },
    ...{ url },
    ...{ data },
    ...configs,
  };
  return await axiosInstance(options);
};

export const createPreSignedUrl = async ({
  filename,
  contentType,
  module,
  externalToken,
}: {
  filename: string;
  contentType: string;
  module?: string;
  externalToken?: string;
}) => {
  let prefix = "s3";
  let axiosOptions = {};

  if (externalToken) {
    prefix = prefix + "/ext";
    axiosOptions = {
      externalToken,
    };
  }

  const result = await ServiceAxios({
    method: "post",
    url: prefix + "/presigned/put",
    service: "aws",
    axiosOptions,
    data: {
      contentType,
      filename,
      module,
    },
  });

  return result.data?.value;
};

export const uploadFileToS3 = async ({
  file,
  url,
  onProgress,
}: {
  file: File | string;
  url: string;
  onProgress?: (percentage: number) => any;
}) => {
  try {
    if (typeof file === "string") throw new Error("Subir no tipo File");

    const data = file;

    const result = await Axios({
      method: "put",
      url: url,
      data,
      configs: {
        headers: {
          "Content-Type": file?.type,
        },
        onUploadProgress: (progressEvent) => {
          if (!progressEvent?.lengthComputable) return;

          const progress: number = Math.round(
            ((progressEvent.loaded || 0) * 100) / (progressEvent.total || 0)
          );

          if (onProgress) onProgress.call(null, progress);
        },
      },
    });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const getBase64File = async ({
  filename,
  module,
  externalToken,
}: {
  filename: string;
  module?: string;
  externalToken?: string;
}) => {
  const file = {};

  if (!!filename) {
    try {
      let prefix = "s3";
      let axiosOptions = {};

      if (externalToken) {
        prefix = prefix + "/ext";
        axiosOptions = {
          externalToken,
        };
      }

      const preSignedURL = await ServiceAxios({
        method: "post",
        service: "aws",
        url: prefix + "/presigned/get",
        axiosOptions,
        data: {
          filename,
          module,
        },
      });

      const base64file = await Axios({
        method: "get",
        url: preSignedURL.data.value.signedUrl,
        data: {},
      });

      return { file: { ...base64file } };
    } catch (err) {
      throw new Error(err);
    }
  }

  return { file };
};

export const uploadUtility = async ({
  file,
  filepath,
  contentType,
  externalToken,
}: IUploadFileToS3) => {
  if (!!file && file.result) {
    let response;

    const buff = Buffer.from(file.result.toString(), "binary").toString(
      "base64"
    );

    try {
      await createPreSignedUrl({
        filename: filepath,
        externalToken,
        contentType,
      }).then(async (value) => {
        try {
          await uploadFileToS3({
            file: buff,
            url: value.signedUrl,
          }).then(async () => {
            try {
              const base64file = await getBase64File({
                filename: filepath,
              });

              response = base64file.file;
            } catch (e) {
              throw new Error(e);
            }
          });
        } catch (e) {
          throw new Error(e);
        }
      });
    } catch (e) {
      throw new Error(e);
    }

    return response;
  }
};

export const getS3Presigned = async ({
  filename,
  module,
  externalToken,
}: getS3PresignedProps) => {
  try {
    if (!filename) throw new Error("Parâmetros obrigatórios não enviados!");

    let prefix = "s3";
    let axiosOptions = {};

    if (externalToken) {
      prefix = prefix + "/ext";
      axiosOptions = {
        externalToken,
      };
    }

    const preSignedURL = await ServiceAxios({
      method: "post",
      service: "aws",
      url: prefix + "/presigned/get",
      axiosOptions,
      data: {
        filename,
        module,
      },
    });

    const url = preSignedURL?.data?.value?.signedUrl || null;

    if (!url) throw new Error("Erro ao buscar url!");

    return { url: url };
  } catch (err) {
    throw new Error(err);
  }
};

export const upload = async ({
  key,
  file,
  fileName,
  module,
  onProgress,
  externalToken,
}: UploadProps) => {
  if (!file || !key) throw new Error("Erro ao fazer upload!");

  const ext = file?.name?.lastIndexOf(".");
  const fileNameExt = fileName?.lastIndexOf(".") || 0;

  if (ext <= 0 && fileNameExt <= 0)
    throw new Error("Erro ao fazer upload, arquivo sem extensão!");

  if (fileName && fileNameExt <= 0)
    fileName += `.${file?.name?.substr(file?.name?.lastIndexOf(".") + 1)}`;

  key += fileName ? "/" + fileName : "/" + file.name;

  const preSignedUrl = await createPreSignedUrl({
    filename: key,
    contentType: file.type,
    module: module,
    externalToken,
  });

  await uploadFileToS3({
    file: file,
    url: preSignedUrl.signedUrl,
    onProgress,
  });

  return {
    key: preSignedUrl.key,
    path: key,
  };
};

export const multipartUpload = async ({
  key,
  file,
  fileName,
  onProgress,
  module,
  externalToken,
}: UploadProps) => {
  const parts = [];
  const uploadedParts = [];
  const chunkSize = 1024 * 1024 * 5;

  let uploadedSize = 0;
  let fileId = null;
  let fileKey = null;

  if (!file || !key) throw new Error("Erro ao fazer upload!");

  const ext = file?.name?.lastIndexOf(".");
  const fileNameExt = fileName?.lastIndexOf(".") || 0;

  if (ext <= 0 && fileNameExt <= 0)
    throw new Error("Erro ao fazer upload, arquivo sem extensão!");

  if (fileName && fileNameExt <= 0)
    fileName += `.${file?.name?.substr(file?.name?.lastIndexOf(".") + 1)}`;

  const sendNext = async () => {
    if (!parts.length) return await completeMultPart();

    const part = parts.pop();

    const sentSize = (part.PartNumber - 1) * chunkSize;
    const chunk = file.slice(sentSize, sentSize + chunkSize);

    await upload({ chunk, part });

    return await sendNext();
  };

  const completeMultPart = async () => {
    const finalizationMultipartInput = {
      fileId: fileId,
      fileKey: fileKey,
      parts: uploadedParts,
      module: module,
    };

    let prefix = "s3";
    let axiosOptions = {};

    if (externalToken) {
      prefix = prefix + "/ext";
      axiosOptions = {
        externalToken,
      };
    }

    const { data } = await ServiceAxios({
      method: "post",
      service: "aws",
      url: prefix + "/multipartUpload/finalizeMultipartUpload",
      axiosOptions,
      data: finalizationMultipartInput,
    });

    const response = data?.value || {};

    return {
      key: response?.Bucket + "/" + response?.Key,
      path: response?.Key,
    };
  };

  const upload = async ({ chunk, part }) => {
    const preSignedUrl = await fetch(part.signedUrl, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Etag",
        "Access-Control-Expose-Headers": "ETag, Content-Length, X-JSON",
      },
      body: chunk,
    });

    const ETag = preSignedUrl?.headers?.get("ETag");

    if (!ETag) throw new Error("Erro ao processar headers do upload");

    const uploadedPart = {
      PartNumber: part.PartNumber,
      ETag: ETag,
    };

    uploadedParts.push(uploadedPart);

    uploadedSize += chunk?.size || 0;

    const sent = Math.min(uploadedSize, file?.size || 0);
    const percentage = Math.round((sent / (file?.size || 0)) * 100);

    if (onProgress) onProgress.call(null, percentage);

    return;
  };

  try {
    key += fileName ? "/" + fileName : "/" + file.name;

    let prefix = "s3";
    let axiosOptions = {};

    if (externalToken) {
      prefix = prefix + "/ext";
      axiosOptions = {
        externalToken,
      };
    }

    const { data: initializeResponse } = await ServiceAxios({
      method: "post",
      service: "aws",
      url: prefix + "/multipartUpload/initializeMultipartUpload",
      axiosOptions,
      data: {
        key,
        module: module,
        contentType: file?.type,
      },
    });

    const AWSFileDataOutput = initializeResponse?.value || {};

    if (!Object.keys(AWSFileDataOutput).length)
      throw new Error("Erro ao iniciar upload!");

    fileId = AWSFileDataOutput.fileId;
    fileKey = AWSFileDataOutput.fileKey;

    const numberOfParts = Math.ceil(file.size / chunkSize);

    const AWSMultipartFileDataInput = {
      fileId: fileId,
      fileKey: fileKey,
      parts: numberOfParts,
      module: module,
    };

    const { data: urlsResponse } = await ServiceAxios({
      method: "post",
      service: "aws",
      url: prefix + "/multipartUpload/getMultipartPreSignedUrls",
      axiosOptions,
      data: AWSMultipartFileDataInput,
    });

    const newParts = urlsResponse?.value || [];

    parts.push(...newParts);

    return await sendNext();
  } catch (e) {
    throw new Error(e);
  }
};

export const uploadHelper = async ({
  key,
  file,
  fileName,
  module,
  onProgress,
  externalToken,
}: UploadProps) => {
  if (typeof file === "string") throw new Error("Subir no tipo File");

  const chunkSize = 1024 * 1024 * 5;

  if (file.size < chunkSize) {
    return await upload({
      key,
      file,
      fileName,
      module,
      onProgress,
      externalToken,
    });
  }

  return await multipartUpload({
    key,
    file,
    fileName,
    module,
    onProgress,
    externalToken,
  });
};
