import { AES, enc, mode, pad } from "crypto-js";

export const encrypt = (value: string) => {
  const key = enc.Utf8.parse("3t6w9z$C&F)J@McQ");
  const iv = enc.Utf8.parse("flash!A%D*G-JaNd");

  const encrypted = AES.encrypt(value, key, {
    iv,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decrypt = (value: string) => {
  const key = enc.Utf8.parse("3t6w9z$C&F)J@McQ");
  const iv = enc.Utf8.parse("flash!A%D*G-JaNd");

  const decrypted = AES.decrypt(value, key, {
    iv,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  return decrypted.toString(enc.Utf8);
};
