import * as Sentry from "@sentry/browser";
import { MonitoringLevel } from "../MonitoringLevel";
import { IMonitoringProvider } from "./IMonitoringProvider";

export class SentryProvider implements IMonitoringProvider {
  captureException(
    error: Error,
    severity: MonitoringLevel,
    extras?: Record<string, any>,
    tags?: Record<string, any>
  ) {
    Sentry.captureException(error, {
      level: severity,
      extra: extras,
      tags,
    });
  }

  captureMessage(
    message: string,
    severity: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    Sentry.captureMessage(message, {
      level: severity,
      extra: extras,
    });
  }

  identifyUser(id: string) {
    Sentry.setUser({ id });
  }
}
