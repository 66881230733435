import { useCallback, useEffect, useState } from "react";
import { ContractModules } from "./types";
import { getFromLS } from "../localStorage";

const PEOPLE_CONTRACT_MODULES_LS_KEY = "people_contract_modules";
const PEOPLE_CONTRACT_MODULES_UPDATED_EVENT = "people_contract_modules_updated";

export const useContractModules = (): ContractModules => {
  const [contractModules, setContractModules] = useState<ContractModules>(
    getCachedContractModules()
  );

  const onContractModulesUpdated = useCallback(
    ({ detail }: CustomEvent<ContractModules>) => {
      setContractModules(detail);
    },
    []
  );

  useEffect(() => {
    window.addEventListener(
      PEOPLE_CONTRACT_MODULES_UPDATED_EVENT,
      onContractModulesUpdated as EventListener
    );
    return () => {
      window.removeEventListener(
        PEOPLE_CONTRACT_MODULES_UPDATED_EVENT,
        onContractModulesUpdated as EventListener
      );
    };
  }, []);

  return contractModules;
};

const getCachedContractModules = (): ContractModules | undefined => {
  const cachedContractModules = getFromLS(
    PEOPLE_CONTRACT_MODULES_LS_KEY
  ) as ContractModules;

  return cachedContractModules;
};
