export * from "./admin-mfa-manager";
export * from "./analytics";
export * from "./monitoring";
export * from "./api";
export * from "./auth";
export * from "./legacyUtility";
export * from "./localStorage";
export * from "./permissions";
export * from "./uploadUtility";
export * from "./viacep";
export * from "./segment";
export * from "./unleash";
export * from "./extract-cnh-data";
export * from "./extract-rg-data";
export * from "./header";
export * from "./feature-flag";
export * from "./crypto";
export * from "./custom-hooks";

import { StartAnalyticsObserver } from "./analytics-observer";
StartAnalyticsObserver();
