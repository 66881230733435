import { Axios } from "../api";
import { epochDateCompare } from "../utils";
import { getAuthJwt } from "../auth";

type ConfirmCodeResponse = {
  isValid: boolean;
  token?: AuthenticatedToken;
};

type AuthenticatedToken = {
  code: string;
  createdAt: Date;
  employeeId: string;
  exp: number;
};

export class AdminMfaManager {
  static ADMIN_MFA_LS_KEY = "ADMIN_MFA_LS_KEY";

  // InMemory variable
  static authenticatedToken?: AuthenticatedToken | undefined;

  /**
   * Get the code that was fetched previously
   * @returns the code current valid code
   */
  static getAuthenticatedCode(employeeId: string): string | undefined {
    if (this.isAuthenticated(employeeId)) {
      return this.authenticatedToken?.code;
    }
    return undefined;
  }

  /**
   * Verify if user need to authenticate with admin mfa or not
   * @returns true | false
   */
  private static isAuthenticated(employeeId: string): boolean {
    if (!this.authenticatedToken) {
      this.authenticatedToken = this.getTokenLocally();
    }
    if (this.authenticatedToken?.employeeId === employeeId) {
      return !this.tokenExpired(this.authenticatedToken);
    }
    return false;
  }

  /**
   *
   * @param companyId the companyId that the user is logged
   * @returns
   */
  static async sendVerificationCode(companyId: string) {
    try {
      const jwt = await getAuthJwt();
      await Axios({
        service: "accessManagement",
        method: "post",
        url: "administrator/send-confirm-code",
        data: {
          authorization: jwt,
          companyId: companyId,
        },
      });
      return true;
    } catch {}
    return false;
  }

  /**
   *
   * @param code the code to be validated on access-management
   * @returns if code is validated or not
   */
  static async verifyCode(code: string): Promise<boolean> {
    // check code online e save locally
    try {
      const request = await Axios({
        service: "accessManagement",
        method: "post",
        url: "administrator/confirm-code",
        data: {
          validationCode: code,
        },
      });
      const response = request.data as ConfirmCodeResponse;
      if (response.token) {
        this.saveTokenLocally(response.token);
      }
      return response.isValid;
    } catch {}
    return false;
  }

  /**
   *
   * @param token Check if local token is still valid
   * @returns true if is expired
   */
  private static tokenExpired(token: AuthenticatedToken) {
    const isExpired = epochDateCompare(token.exp);
    return isExpired;
  }

  /**
   * Save the token on localStorage
   * @param token
   */
  private static saveTokenLocally(token: AuthenticatedToken) {
    this.authenticatedToken = token;
    localStorage.setItem(
      AdminMfaManager.ADMIN_MFA_LS_KEY,
      JSON.stringify(token)
    );
  }

  /**
   * Get the saved token on localStorage
   */
  private static getTokenLocally(): AuthenticatedToken | undefined {
    const item = localStorage.getItem(AdminMfaManager.ADMIN_MFA_LS_KEY);
    if (item) {
      return JSON.parse(item) as AuthenticatedToken;
    }
    return undefined;
  }
}
