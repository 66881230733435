import {
  UnleashClient,
  InMemoryStorageProvider,
  IToggle,
  IConfig,
} from "unleash-proxy-client";

export interface IPermissionsFlags {
  flags: IToggle[];
  flagIsEnabled?(flagName: string): boolean;
}

export enum EEnvironment {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

export async function getPermissionsFlags(
  companyId,
  employeeId,
  economicGroupId
): Promise<IPermissionsFlags> {
  const environment: string =
    process.env.BUILD_ENV == "production"
      ? EEnvironment.PRODUCTION
      : EEnvironment.DEVELOPMENT;

  const unleash = new UnleashClient({
    url: `https://unleash-benefits-proxy-${environment}.us.flashapp.services/proxy`,
    clientKey: process.env.UNLEASH_BENEFITS_PROXY_KEY || "proxy-dev",
    appName: "flash_os",
    refreshInterval: 3600, // 1 hour
    metricsInterval: 3600, // 1 hour
    environment: environment,
    storageProvider: new InMemoryStorageProvider(),
    fetch: window.fetch.bind(window),
  });

  await unleash.updateContext({
    properties: {
      companyNanoId: companyId,
      employeeNanoId: employeeId,
      economicGroupId,
    },
  });
  await unleash.start();
  const flags: IPermissionsFlags["flags"] = unleash.getAllToggles();

  return {
    flags,
    flagIsEnabled: (flagName: string) =>
      flags?.some((_flag) => _flag.name === flagName && _flag.enabled),
  };
}

type GetUnleashConfigInput = {
  appName: string;
  refreshInterval?: number;
  metricsInterval?: number;
};

const DEFAULT_UNLEASH_INTERVAL = 3600; // 1 hour

/**
 * Returns a unleash config to use on unleash <FlagProvider />.
 * Use example is on flashstage:
 * {@link https://backstage.flashapp.systems/docs/default/component/hros-web-utility}
 * @param {GetUnleashConfigInput} input
 * @returns {IConfig} Unleash configs
 */
export const getUnleashConfig = ({
  appName,
  refreshInterval = DEFAULT_UNLEASH_INTERVAL,
  metricsInterval = DEFAULT_UNLEASH_INTERVAL,
}: GetUnleashConfigInput): IConfig => {
  const environment: string =
    process.env.BUILD_ENV === "production" ? "production" : "development";

  return {
    url: `https://unleash-benefits-proxy-${environment}.us.flashapp.services/proxy`,
    clientKey: process.env.UNLEASH_BENEFITS_PROXY_KEY || "proxy-dev",
    appName,
    refreshInterval,
    metricsInterval,
    environment,
  };
};
