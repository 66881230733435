import { getAccessTokenPayloadSync } from "./api";
import { getFromLS } from "./localStorage";

type ObjectLiteral = {
  [key: string]: string;
};

interface TrackEventProps {
  name: string;
  params?: ObjectLiteral;
  module: string;
  businessUnit: string;
}

interface IdentifyProps {
  userId: string;
  params?: ObjectLiteral;
  module: string;
  businessUnit: string;
}

interface SegmentTrackingProps {
  track: string;
  module: string;
  businessUnit: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}

const defaultTrackProps = () => {
  const user = getAccessTokenPayloadSync();
  const employeeId = user?.employeeId;
  const url = new URL(document.location.href);
  const companyId =
    url.searchParams.get("company") || user?.companyId || undefined;
  return { employeeId, companyId };
};

export const segmentIdentify = ({
  userId,
  params,
  module,
  businessUnit,
}: IdentifyProps) => {
  const defaultParams = defaultTrackProps();
  // @ts-ignore
  window?.analytics?.identify(userId, {
    ...params,
    ...defaultParams,
    module,
    businessUnit,
  });
};

export const segmentPageTracking = ({
  name,
  params,
  module,
  businessUnit,
}: TrackEventProps) => {
  const defaultParams = defaultTrackProps();
  const buildEnv = process.env.BUILD_ENV;
  // @ts-ignore
  window?.analytics?.page(name, {
    ...params,
    ...defaultParams,
    businessUnit,
    module,
    environment: buildEnv,
  });
};

export const segmentEventTracking = ({
  name,
  params,
  module,
  businessUnit,
}: TrackEventProps) => {
  const defaultParams = defaultTrackProps();
  const buildEnv = process.env.BUILD_ENV;
  // @ts-ignore
  window?.analytics?.track(name, {
    ...params,
    ...defaultParams,
    businessUnit,
    module,
    environment: buildEnv,
  });
};

// legacy helper function
export const segmentTracking = ({
  track,
  params,
  identify,
  module,
  businessUnit,
}: SegmentTrackingProps) => {
  const accessToken = getFromLS("hrosAccessToken");
  const employeeId = accessToken?.employeeId;

  const buildEnv = process.env.BUILD_ENV;

  // @ts-ignore
  window?.analytics?.track(track, {
    ...params,
    businessUnit,
    module,
    employeeId,
    environment: buildEnv,
  });

  if (identify)
    // @ts-ignore
    window?.analytics?.identify(identify?.id, { ...identify?.params });
};
