import { useState, useCallback, useEffect } from "react";
import { legacyGetFromLS } from "./legacyUtility";

const LEGACY_PERMISSIONS_UPDATED_EVENT = "legacyLSUpdated";
const LEGACY_PERMISSIONS_UPDATE_STARTED = "started";
const LEGACY_PERMISSIONS_UPDATE_FINISHED = "finished";

export function useLegacyPermissions() {
  const [permissionsState, setPermissionsState] = useState(getPermissions());
  const [isLoading, setIsLoading] = useState(false);

  const onLegacyLSUpdated = ({ detail }: CustomEvent<string>) => {
    switch (detail) {
      case LEGACY_PERMISSIONS_UPDATE_FINISHED:
        const updatedPermissions = getPermissions();
        setPermissionsState(updatedPermissions);
        setIsLoading(false);
        return;
      case LEGACY_PERMISSIONS_UPDATE_STARTED:
        setIsLoading(true);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener(
      LEGACY_PERMISSIONS_UPDATED_EVENT,
      onLegacyLSUpdated as EventListener
    );
    return () => {
      window.removeEventListener(
        LEGACY_PERMISSIONS_UPDATED_EVENT,
        onLegacyLSUpdated as EventListener
      );
    };
  }, []);

  const memoCheckSectionPermission = useCallback(
    (section) => checkSectionPermission(permissionsState, section),
    [permissionsState]
  );

  const memoCheckPathPermission = useCallback(
    (path) => checkPathPermission(permissionsState, path),
    [permissionsState]
  );

  return {
    permissions: permissionsState,
    checkSectionPermission: memoCheckSectionPermission,
    checkPathPermission: memoCheckPathPermission,
    isLoading: isLoading,
  };
}

export function getPermissions() {
  const clientData = legacyGetFromLS("clientData");

  // parse Benefit section
  function benefitsPageViewPermissions(clientData: any) {
    const featureConfig = clientData?.featureConfig;
    const permissions = clientData?.permissions;

    // parse Benefit view permissions section
    const viewPermissions = [];
    if (permissions) {
      viewPermissions.push("/benefits" + "/dashboard/company/employees");

      viewPermissions.push("/benefits" + "/dashboard/company/benefits");

      viewPermissions.push("/benefits" + "/dashboard/corporateProfile");
      viewPermissions.push("/benefits" + "/dashboard/users");
      viewPermissions.push("/benefits" + "/dashboard/fileSupport");
    }

    if (permissions?.admins?.adminPermissionsEdit)
      viewPermissions.push("/benefits" + "/dashboard/company/register");

    if (featureConfig?.marketplaceEnabled)
      viewPermissions.push("/benefits" + "/dashboard/company/marketplace");

    if (featureConfig?.apiEnabled)
      viewPermissions.push("/benefits" + "/dashboard/company/api");

    if (permissions?.billets?.pageView)
      viewPermissions.push("/benefits" + "/dashboard/company/payments");

    if (permissions?.deposits?.pageView)
      viewPermissions.push("/benefits" + "/dashboard/company/deposits");

    if (featureConfig?.flashCashEnabled && permissions?.flashCash?.pageView)
      viewPermissions.push("/benefits" + "/dashboard/company/balance");

    if (permissions?.reports?.pageView)
      viewPermissions.push("/benefits" + "/dashboard/company/reports");

    if (featureConfig?.expenseManagementEnabled)
      viewPermissions.push(
        "/benefits" + "/dashboard/company/expenseManagement"
      );

    if (featureConfig?.flashPointsEnabled)
      viewPermissions.push("/benefits" + "/dashboard/company/points");

    return viewPermissions;
  }
  const benefitsPermissions = {
    pageView: benefitsPageViewPermissions(clientData),
  };

  // parse People section

  // parse Expense view permissions section
  const expenseViewPermissions = [];
  if (clientData?.permissions) {
    expenseViewPermissions.push("/cards");
  }
  return {
    root: { pageView: expenseViewPermissions },
    benefits: benefitsPermissions,
  };
}

export const checkSectionPermission = (permissions: any, section: string) => {
  switch (section) {
    case "flash_benefits":
      return Boolean(permissions?.benefits?.pageView?.length);
    default:
      return true;
  }
};

export const checkPathPermission = (permissions: any, path: string) => {
  /** Checks if the path has one prefixes */
  const hasPrefix = path?.includes("/benefits");
  const isCardRoute = path === "/cards";
  // TODO: Update feature when permissions is finished

  return (
    (!isCardRoute && !hasPrefix) ||
    permissions?.benefits?.pageView?.includes(path) ||
    permissions?.root?.pageView?.includes(path)
  );
};
