import { useEffect, useCallback } from "react";
import { getMountedApps } from "single-spa";

type Route = {
  path: string;
  hideHeader: boolean;
};

export const registerRoutes = (detail: Route[]) => {
  dispatchEvent(new CustomEvent("registerRoutes", { detail }));
};

export const useRegisterRoutes = (routes: Route[]) => {
  const headerAppName = "@flash-hros/header";

  const onAppChangeEvent = useCallback(({ detail }: CustomEvent<any>) => {
    if (detail.newAppStatuses[headerAppName] === "MOUNTED") {
      registerRoutes(routes);

      window.removeEventListener(
        "single-spa:app-change",
        onAppChangeEvent as EventListener
      );
    }
  }, []);

  useEffect(() => {
    const apps = getMountedApps();
    if (apps.includes(headerAppName)) {
      registerRoutes(routes);
    } else {
      window.addEventListener(
        "single-spa:app-change",
        onAppChangeEvent as EventListener
      );
      return () => {
        window.removeEventListener(
          "single-spa:app-change",
          onAppChangeEvent as EventListener
        );
      };
    }
  }, []);
};
