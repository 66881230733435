import { SentryProvider } from "./providers/SentryProvider";
import { MonitoringLevel } from "./MonitoringLevel";
import { IMonitoringProvider } from "./providers/IMonitoringProvider";

const monitoringProviders: IMonitoringProvider[] = [new SentryProvider()];

export class MonitoringManager {
  /**
   *
   * @param project - This property is used to know which monitoring panel the error will be logged
   * @param error - Error to be captured
   * @param severity - Level of message (error as default)
   * @param extras - Extras properties
   */

  public static readonly utilityProjectDsn =
    "https://3728e4fd3566c81d474b919f7659a174@o266934.ingest.sentry.io/4505686536355840";
  static captureException = (
    project: string,
    error: Error,
    severity: MonitoringLevel = "error",
    extras?: Record<string, any>,
    tags?: Record<string, any>
  ) => {
    monitoringProviders.forEach((provider) => {
      provider.captureException(
        error,
        severity,
        {
          ...extras,
          project,
        },
        tags
      );
    });
  };

  /**
   * Capture an custom error message
   * @param project - This property is used to know which monitoring panel the error will be logged
   * @param message - Message to be captured
   * @param severity - Level of message (error as info)
   * @param extras - Extras properties
   */
  static captureMessage = (
    project: string,
    message: string,
    severity: MonitoringLevel = "info",
    extras?: Record<string, string>
  ) => {
    monitoringProviders.forEach((provider) => {
      provider.captureMessage(message, severity, {
        ...extras,
        project,
      });
    });
  };

  /**
   *
   * @param id - User id
   */
  static identifyUser(id: string) {
    monitoringProviders.forEach((provider) => {
      provider.identifyUser(id);
    });
  }
}
