import { useCallback, useEffect, useState } from "react";
import { Company, EmployeePermissions } from "./types";
import { usePermissions } from "./usePermissions";
import { getFromLS } from "../localStorage";
import { useDidUpdateEffect } from "./useDidUpdateEffect";
import { MonitoringManager } from "../monitoring";
import { legacyGetFromLS, legacySetInLS } from "../legacyUtility";

const HEADER_COMPANY_SELECTED = "header_company_selected";
const COMPANY_SELECTED_LS_KEY = "selectedCompany";

const verifyCompanyReceived = (companyReceived, origin: string) => {
  if (companyReceived?.cnpj) {
    const error = new Error("selectedCompany with cnpj");
    const projectDsn = MonitoringManager.utilityProjectDsn;
    MonitoringManager.captureException(projectDsn, error, "error", {
      companyReceived,
      origin,
    });
    const companyVerified = {
      ...companyReceived,
      registrationNumber: companyReceived.cnpj,
    };
    return companyVerified;
  }
  return companyReceived;
};

export const useSelectedCompany = (): { selectedCompany: Company } => {
  const permissions = usePermissions();
  const [selectedCompany, setSelectedCompany] = useState<Company>(
    verifyCompanyReceived(
      getInitialSelectedCompany(permissions),
      "initial-state"
    )
  );

  useEffect(() => {
    const url = new URL(document.location.href);
    if (selectedCompany) {
      setLegacyCompanyClientData(selectedCompany.registrationNumber);
      const currentCompanyParam = url.searchParams.get("company");
      url.searchParams.set("company", selectedCompany.id);

      if (!currentCompanyParam) {
        window.history.replaceState({}, "", url);
      } else if (currentCompanyParam !== selectedCompany.id) {
        window.history.pushState({}, "", url);
      }
    }
  }, [selectedCompany]);

  useDidUpdateEffect(() => {
    setSelectedCompany(
      verifyCompanyReceived(permissions.companies[0], "update-permissions")
    );
  }, [permissions]);

  const onHeaderCompanySelected = useCallback(
    ({ detail }: CustomEvent<Company>) => {
      setSelectedCompany(verifyCompanyReceived(detail, "header-event"));
    },
    []
  );

  const popstateEventHandler = () => {
    const url = new URL(document.location.href);
    const currentCompanyParam = url.searchParams.get("company");
    if (!currentCompanyParam) return;
    const company = permissions.companies.find(
      ({ id }) => id === currentCompanyParam
    );
    if (!company) return;
    if (currentCompanyParam !== selectedCompany.id) {
      setSelectedCompany(company);
    }
  };

  useEffect(() => {
    window.addEventListener(
      HEADER_COMPANY_SELECTED,
      onHeaderCompanySelected as EventListener
    );
    return () => {
      window.removeEventListener(
        HEADER_COMPANY_SELECTED,
        onHeaderCompanySelected as EventListener
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", popstateEventHandler as EventListener);
    return () => {
      window.removeEventListener(
        "popstate",
        popstateEventHandler as EventListener
      );
    };
  }, [selectedCompany]);

  return { selectedCompany };
};

const getInitialSelectedCompany = (
  permissions: EmployeePermissions
): Company => {
  const url = new URL(document.location.href);
  const companyIdUrlParam = url.searchParams.get("company");
  const selectedCompanyInLs = getFromLS(COMPANY_SELECTED_LS_KEY) as
    | Company
    | undefined;

  const companiesWithPermissions = permissions.companies.filter(
    (company) => company.permissions.length || permissions.isAdmin
  );

  if (
    companyIdUrlParam &&
    companiesWithPermissions.some((c) => c.id === companyIdUrlParam)
  ) {
    return companiesWithPermissions.find((c) => c.id === companyIdUrlParam);
  }

  if (
    selectedCompanyInLs &&
    companiesWithPermissions.some((c) => c.id === selectedCompanyInLs.id)
  ) {
    return selectedCompanyInLs;
  }

  return permissions.companies[0];
};

function setLegacyCompanyClientData(registrationNumber: string) {
  const startedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "started",
  });
  const finishedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "finished",
  });
  dispatchEvent(startedEvent);

  const clientData = legacyGetFromLS("clientData");
  const company = clientData?.companies?.find(
    (c: { cnpj: string; _id: string }) => c.cnpj === registrationNumber
  );
  if (company) {
    const {
      companySteps,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isOldStructure,
      path,
      permissions,
      reports,
    } = company;
    legacySetInLS("clientData", {
      ...clientData,
      companyId: company._id,
      companySteps,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isOldStructure,
      path,
      permissions,
      reports,
    });
  } else {
    const clearedData = {
      type: "company",
      timestamp: Date(),
      adminId: clientData?.adminId,
      adminName: clientData?.nameAssociated,
      companies: clientData?.companies,
      emailAssociated: clientData?.emailAssociated,
      env: clientData?.env,
      superUser: clientData?.superUser,
      userSteps: clientData?.userSteps || {},
      steps: clientData?.steps || {},
    };
    legacySetInLS("clientData", { ...clearedData });
  }
  dispatchEvent(finishedEvent);
}
