import axios from "axios";

export const getCep = async (cep: string) => {
  // typescript didnt have Promise.any typing
  const PromiseTS = Promise as any;

  try {
    const cepData = await PromiseTS.any([
      (async () => {
        const url = `https://viacep.com.br/ws/${cep}/json/`;

        const response: any = await axios(url);

        if (!response?.data) throw new Error();

        const data = response?.data;

        let formattedResponse = {
          cep: data?.cep || "",
          logradouro: data?.logradouro || "",
          bairro: data?.bairro || "",
          localidade: data?.localidade || "",
          uf: data?.uf || "",
        };

        return formattedResponse;
      })(),
      (async () => {
        const url = `https://cdn.apicep.com/file/apicep/${cep}.json`;

        const response: any = await axios(url);

        if (!response?.data) throw new Error();

        const data = response?.data;

        let formattedResponse = {
          cep: data?.code || "",
          logradouro: data?.address || "",
          bairro: data?.district || "",
          localidade: data?.city || "",
          uf: data?.state || "",
        };

        return formattedResponse;
      })(),
      (async () => {
        const url = `https://brasilapi.com.br/api/cep/v1/${cep}`;

        const response: any = await axios(url);

        if (!response?.data) throw new Error();

        const data = response?.data;

        let formattedResponse = {
          cep: data?.cep || "",
          logradouro: data?.street || "",
          bairro: data?.neighborhood || "",
          localidade: data?.city || "",
          uf: data?.state || "",
        };

        return formattedResponse;
      })(),
    ]);

    return cepData;
  } catch (err) {
    throw err;
  }
};
