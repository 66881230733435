import { AnalyticEventType, StringMap } from "./AnalyticEventType";
import { IAnalyticsProvider } from "./IAnalyticsProvider";
import { PendoProvider } from "./providers";
import { HubspotProvider } from "./providers/HubspotProvider";

export class AnalyticsManager implements IAnalyticsProvider {
  static shared: AnalyticsManager;

  constructor(
    private readonly providers: IAnalyticsProvider[] = [
      new PendoProvider(),
      new HubspotProvider(),
    ]
  ) {
    if (!AnalyticsManager.shared) {
      AnalyticsManager.shared = this;
    }
  }

  identify(id?: string, properties?: StringMap) {
    this.providers.forEach((provider) => {
      provider.identify(id, properties);
    });
  }

  track<T extends AnalyticEventType<string>>(event: T): void {
    this.providers.forEach((provider) => {
      provider.track(event);
    });
  }

  setPageEvent<T extends AnalyticEventType<string>>(event: T): void {
    this.providers.forEach((provider) => {
      provider.setPageEvent(event);
    });
  }
}
