import React from "react";
import { MonitoringManager } from "./MonitoringManager";
import { AppProps } from "single-spa";

type Props = React.PropsWithChildren<AppProps>;

/**
 * @deprecated Use sentryWebpackPlugin instead
 * @link https://docs.sentry.io/platforms/javascript/configuration/micro-frontend-support/
 */
export abstract class MonitoringErrorBoundary extends React.Component<Props> {
  abstract project: string;

  constructor(readonly props: Props) {
    super(props);
  }

  componentDidCatch(error, _errorInfo) {
    MonitoringManager.captureException(this.project, error);
  }

  render() {
    return this.props.children;
  }
}
