import { AnalyticEventType, StringMap } from "../AnalyticEventType";
import { IAnalyticsProvider } from "../IAnalyticsProvider";

export class HubspotProvider implements IAnalyticsProvider {
  // @ts-ignore
  hsq = (window._hsq = window._hsq || []);

  track<T extends AnalyticEventType<string>>(event: T): void {
    this.hsq.push([event.name, event.params]);
  }

  identify(id: string, properties?: StringMap): void {
    if (properties?.isSuperAdmin || properties?.isCompanyAdmin) {
      this.hsq.push([
        "identify",
        {
          id,
          superadmin_na_plataforma_da_flash: properties?.isSuperAdmin
            ? "true"
            : "false",
          companyadmin: properties?.isCompanyAdmin ? "true" : "false",
          email: properties?.email,
        },
      ]);
    }
  }

  setPageEvent<T extends AnalyticEventType<string>>(event: T): void {
    this.hsq.push(["setPath", event.name]);
    this.hsq.push(["trackPageView"]);
  }
}
