import { Auth, Amplify } from "aws-amplify";

export const cognitoConfig = {
  staging: {
    Auth: {
      endpoint: "https://hros-auth.flashapp.dev/",
      region: "us-east-1",
      userPoolId: "us-east-2_lDhHlHCgu",
      userPoolWebClientId: "36603hvuv4730jglqu479hqhbd",
      oauth: {},
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
  },
  production: {
    Auth: {
      endpoint: "https://hros-auth.flashapp.services/",
      region: "us-east-1",
      userPoolId: "us-east-2_OwzwM6gCp",
      userPoolWebClientId: "4r4ki1jqohppg2dko3uf7rvq13",
      oauth: {},
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
  },
};

Amplify.configure(
  cognitoConfig[
    process.env.BUILD_ENV === "production" ? "production" : "staging"
  ].Auth
);

/**
 * Fetch current jwt. Throws if user is not authenticated
 * @returns jwt string
 */
const getAuthJwt = async (): Promise<string> => {
  const currentSession = await Auth.currentSession();
  const IDToken = currentSession.getIdToken();
  return IDToken.getJwtToken();
};

export type AuthenticatedUser = {
  sub: string;
  username: string;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
  email: string;
  phoneNumber: string;
  name?: string;
};

/**
 * Return the authenticated user from local session
 * @returns AuthenticatedUser | undefined
 */
const getAuthenticatedUser = async (): Promise<
  AuthenticatedUser | undefined
> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      return {
        sub: user.attributes.sub,
        emailVerified: user.attributes.email_verified,
        phoneNumberVerified: user.attributes.phone_number_verified,
        username: user.attributes.preferred_username,
        phoneNumber: user.attributes.phone_number,
        email: user.attributes.email,
        name: user.attributes.name,
      };
    }
  } catch {
    return undefined;
  }
};

export { Auth, getAuthJwt, getAuthenticatedUser };
