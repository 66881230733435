import { useCallback, useEffect, useState } from "react";
import { EmployeePermissions } from "./types";
import { getFromLS } from "../localStorage";
import { appendRedirectUrlToPath } from "../utils";
import { navigateToUrl } from "single-spa";

const EMPLOYEE_PERMISSIONS_LS_KEY = "employee_permissions";
const EMPLOYEE_PERMISSIONS_UPDATED_EVENT = "employee_permissions_updated";

export const usePermissions = (): EmployeePermissions => {
  const [employeePermissions, setEmployeePermissions] =
    useState<EmployeePermissions>(getCachedPermissions());

  const onPermissionsUpdated = useCallback(
    ({ detail }: CustomEvent<EmployeePermissions>) => {
      setEmployeePermissions(detail);
    },
    []
  );

  useEffect(() => {
    window.addEventListener(
      EMPLOYEE_PERMISSIONS_UPDATED_EVENT,
      onPermissionsUpdated as EventListener
    );
    return () => {
      window.removeEventListener(
        EMPLOYEE_PERMISSIONS_UPDATED_EVENT,
        onPermissionsUpdated as EventListener
      );
    };
  }, []);

  return employeePermissions;
};

const getCachedPermissions = (): EmployeePermissions => {
  const cachedPermissions = getFromLS(
    EMPLOYEE_PERMISSIONS_LS_KEY
  ) as EmployeePermissions;

  if (!cachedPermissions) {
    navigateToUrl(appendRedirectUrlToPath("/authentication/access-selection"));
    return { isAdmin: false, companies: [] };
  }
  return cachedPermissions;
};
