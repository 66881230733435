import { Axios as ServiceAxios } from "./api";

interface DropzoneFile {
  id: string;
  data?: string | ArrayBuffer | null | undefined;
  progress: number;
  loaded: boolean;
  error: boolean;
  file: File;
  mime?: string;
}

export const extractRGData = async (file: DropzoneFile) => {
  return await ServiceAxios({
    method: "post",
    url: "/textract/rg",
    service: "aws",
    axiosOptions: {},
    data: {
      document: file[0].data.split(",")[1],
    },
  });
};
